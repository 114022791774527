import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { decode } from 'he';
import AccordionCard from '../../atoms/AccordionCard/AccordionCard';
import Button from '../../atoms/Button/Button';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import FormInputField from '../../atoms/FormInputField/FormInputField';
import SelectField from '../../atoms/SelectField/SelectField';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { uploadMedia, wpAll, wpApi } from '../../../helpers/wordpress';
import { checkChangedField, getBase64 } from '../../../helpers/general';
import { getPerson } from '../../../helpers/ortto';
import { toast } from 'react-toastify';
import Add from './logos/Add';
import Delete from './logos/Delete';
import Search from './logos/Search';
import RichTextField from '../../atoms/RichTextField/RichTextField';
import Toggle from '../../atoms/Toggle/Toggle';
import ApprovalToggle from '../../molecules/ApprovalToggle/ApprovalToggle';
import Icon from '../../atoms/Icon/Icon';
import Dialog from '../../atoms/Dialog/Dialog';
import DataTable from '../DataTable/DataTable';

import * as styles from './ClubDetails.module.css';
import MeetingDetail from '../MeetingDetail/MeetingDetail';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const findObjectByDatabaseId = (ids, collections) => {
  const collectionList = [];
  if (ids && collections)
    ids.forEach(id => {
      const findTag = collections.find(
        collection => collection.databaseId === id
      );
      if (findTag) collectionList.push(findTag);
    });
  return collectionList;
};

const ClubDetails = ({ club, forApproval = false }) => {
  const fileRef = useRef();
  const [data, setData] = useState({
    fomo: forApproval
      ? club.acf.fomo
      : get(club, "acf.draft_content.fomo", club.acf.fomo),
    meetups: forApproval
      ? club.acf.meetups
      : get(club, "acf.draft_content.meetups", club.acf.meetups),
    why_join: forApproval
      ? club.acf.why_join
      : get(club, "acf.draft_content.why_join", club.acf.why_join),
    content: forApproval
      ? club.content.rendered
      : get(club, "acf.draft_content.content", club.content.rendered),
    disable_free_memberships: forApproval
      ? club.acf.disable_free_memberships
      : get(club, "acf.draft_content.disable_free_memberships", club.acf.disable_free_memberships),
    club_execs: [],
    fees: [],
    categories: [],
    tags: [],
    groups: [],
  });

  const [pendingCategories, setPendingCategories] = useState([]);
  const [pendingTags, setPendingTags] = useState([]);

  const [approvals, setApprovals] = useState({
    content: false,
    disable_free_memberships: false,
    fees: false,
    full_title: false,
    agm_month: false,
    election_month: false,
    club_categories: false,
    club_tags: false,
    abbr: false,
    approval_fees: false,
    fomo: false,
    logo: false,
    title: false,
    why_join: false,
    meetups: false,
    contact: false,
    phone: false,
    mobile: false,
    email: false,
    tiktok: false,
    facebook: false,
    instagram: false,
    discord: false,
    linkedin: false,
    twitter: false,
  });
  const [compareData, setCompareData] = useState(false);
  const [tagSearch, setTagSearch] = useState('');
  const [groupSearch, setGroupSearch] = useState('');
  const [showTagItems, setShowTagItems] = useState(false);
  const [showGroupItems, setShowGroupItems] = useState(false);
  const [logoImage, setLogoImage] = useState();
  const [logoDraftImage, setLogoDraftImage] = useState();
  const [saving, setSaving] = useState(false);
  const [toggleNewTag, setToggleNewTag] = useState(false);
  const [newTag, setNewTag] = useState(null);
  const [toggleNewGroup, setToggleNewGroup] = useState(false);
  const [newGroup, setNewGroup] = useState(null);
  const [execData, editExec] = useState(false);
  const [historyData, setHistoryData] = useState(false);
  const [activeMeeting, setActiveMeeting] = useState(false);

  const { allWpClubTag, allWpClubGrouping, allWpClubCategory } = useStaticQuery(graphql`
    query {
      allWpClubGrouping {
        nodes {
          databaseId
          name
        }
      }
      allWpClubTag {
        nodes {
          databaseId
          name
        }
      }
      allWpClubCategory {
        nodes {
          name
          databaseId
        }
      }
    }
  `);

  /**
   *
   * @param {object} newTag tab object
   * @param {boolean} shouldDelete check if tag should be delete from list
   */
  const tagChangeHandler = (newTag, shouldDelete = false) => {
    const newData = { ...data };
    if (shouldDelete) {
      const tags = newData.tags.filter(
        tag => tag.databaseId !== newTag.databaseId
      );
      newData.tags = tags;
    } else if (
      !newData.tags.find(tag => tag.databaseId === newTag.databaseId)
    ) {
      newData.tags.push(newTag);
    }
    setData(newData);
  };

  const createNewTag = async () => {
    const result = await wpApi('club_tags', 'POST', {name: newTag});
    if (String(result.status).startsWith("2")) {
      tagChangeHandler({databaseId: result.response.id, name: result.response.name});
      setToggleNewTag(false);
    }
  }

  const handleNewTag = (id, value) => {
    setNewTag(value);
  }

  const groupChangeHandler = (newGroup, shouldDelete = false) => {
    const newData = { ...data };
    if (shouldDelete) {
      const groups = newData.groups.filter(
        group => group.databaseId !== newGroup.databaseId
      );
      newData.groups = groups;
    } else if (
      !newData.groups.find(group => group.databaseId === newGroup.databaseId)
    ) {
      newData.groups.push(newGroup);
    }
    setData(newData);
  };

  const createNewGroup = async () => {
    const result = await wpApi('club_groups', 'POST', {name: newGroup});
    if (String(result.status).startsWith("2")) {
      groupChangeHandler({databaseId: result.response.id, name: result.response.name});
      setToggleNewGroup(false);
    }
  }

  const handleNewGroup = (id, value) => {
    setNewGroup(value);
  }

  /**
   *
   * @param {*} newTag
   */
  const categoryChangeHandler = category => {
    const newData = { ...data };
    if (
      !newData.categories.find(
        cat => cat.databaseId === Number(category.currentTarget.value)
      )
    ) {
      const findCategory = allWpClubCategory.nodes.find(
        node => node.databaseId === Number(category.currentTarget.value)
      );
      if (findCategory) newData.categories.push(findCategory);
    } else {
      newData.categories = newData.categories.filter(
        cat => cat.databaseId !== Number(category.currentTarget.value)
      );
    }
    setData(newData);
  };

  const disableFeesChangeHandler = disableFees => {
    const newData = { ...data };
    if (disableFees.currentTarget.checked) {
      newData.disable_free_memberships = true;
    } else {
      newData.disable_free_memberships = false;
    }
    setData(newData);
  };

  /**
   *
   * @param {*} id
   * @param {*} value
   */
  const changeEventHandler = (id, value) => {
    let tempData = Object.assign({}, data);
    tempData[id] = value;
    setData(tempData);
  };

  /**
   *
   * @param {*} id
   * @param {*} value
   */
  const approvalHandler = (id, value) => {
    let tempData = Object.assign({}, approvals);
    tempData[id] = value;
    setApprovals(tempData);
  };

  const feeChangeHandler = (value, field, index, addFee = false) => {
    const newData = { ...data };
    const newFees = newData.fees;
    if (addFee) {
      newFees.push({ value: '', label: '' });
      newData.fees = newFees;
    } else {
      newData.fees[index][field] = value;
    }
    setData(newData);
  };

  const removeFee = (index) => {
    const newData = { ...data };
    const newFees = newData.fees;

    newFees.splice(index, 1);
    newData.fees = newFees;

    setData(newData);
  }

  const fetchExecDetails = async memberNumber => {
    if (memberNumber !== '' && (!execData.name || execData.name === '')) {
      const _execData = {...execData};
      const person = await getPerson(['first', 'last', 'email', 'MemberNumber'], memberNumber, 'str:cm:membernumber');
        
      if (String(person.status).startsWith('2') && person.response.contacts) {
        const personData = person.response.contacts[0];
        if (personData && personData.fields) {
          _execData.name = `${personData.fields['str::first']} ${personData.fields['str::last']}`;
        }
        if (!_execData.email || _execData.email === '') _execData.email = personData ? personData.fields['str::email'] : '';
      } else {
        _execData.name = 'Not yet defined';
      }

      editExec(_execData);
    } else if (memberNumber === '') {
      const _execData = {...execData};
      _execData.name = '';
      _execData.email = '';
      editExec(_execData);
    }
  }

  const clubExecChangeHandler = (value, field) => {
    const newData = { ...execData };
    newData[field] = value;
    editExec(newData);
  };

  const submitExec = () => {
    const newData = { ...data };
    if (!newData.club_execs) {
      newData.club_execs = [];
    }
    newData.club_execs[execData.index] = execData;
    setData(newData);
    editExec(false);
  }

  const removeExec = (_execData) => {
    const newData = { ...data };
    const newExecs = newData.club_execs;
    const foundExecs = newExecs.filter((exec) => exec.member_number !== _execData.member_number);

    // newExecs.splice(_execData.index, 1);
    newData.club_execs = foundExecs;

    setData(newData);
  }

  const _fetchHistory = () => {
    const makeCall = async () => {
      const history = await wpAll(`igm_agm_history?club=${club.id}`);

      setHistoryData(history.response.map(h => {
        return {
          id: h.id,
          meeting_name: h.title.rendered,
          object: h
        }
      }));
    }

    makeCall()
  }

  const fetchHistory = () => {
    if (!historyData) {
      _fetchHistory();
    }
    return historyData ? (
      <>
        <DataTable
          tableData={historyData}
          headingKeys={[
            { label: 'Meeting Name', data_key: 'meeting_name', sortable: true }
          ]}
          rowActions={[
              { label: 'Details', cta: data => setActiveMeeting(data) },
          ]}
        />
        <Dialog open={activeMeeting} size="lg" onCancel={() => setActiveMeeting(false)} hideBtnOk={true} cancelBtnText='Close'>
          <MeetingDetail history={activeMeeting.object} />
        </Dialog>
      </>
    ) : (
      <>
        Fetching history...
      </>
    )
  }

  const submitClubHandler = async () => {
    try {
      setSaving(true);
      let imageId;
      if (logoImage) {
        const base64Image = await getBase64(logoImage);
        const { response: image } = await uploadMedia(
          logoImage.name,
          base64Image,
          logoImage.type
        );
        imageId = image.id;
      }

      //Check if field has been changed before sending it to wordpress
      const acfFields = {
        draft_content: {
          abbr: checkChangedField(
            compareData.abbr,
            club.acf.draft_content?.abbr,
            data.abbr
          ),
          agm_month: checkChangedField(
            compareData.agm_month,
            club.acf.draft_content?.agm_month,
            data.agm_month
          ),
          election_month: checkChangedField(
            compareData.election_month,
            club.acf.draft_content?.election_month,
            data.election_month
          ),
          club_categories: checkChangedField(
            compareData.club_categories,
            club.acf.draft_content?.club_categories,
            data.categories
          ).map(cat => cat.databaseId),
          club_tags: checkChangedField(
            compareData.club_tags,
            club.acf.draft_content?.club_tags,
            data.tags
          ).map(tag => tag.databaseId),
          content: checkChangedField(
            compareData.content,
            club.acf.draft_content?.content,
            data.content
          ),
          meetups: checkChangedField(
            compareData.meetups,
            club.acf.draft_content?.meetups,
            data.meetups
          ),
          fomo: checkChangedField(
            compareData.fomo,
            club.acf.draft_content?.fomo,
            data.fomo
          ),
          title: checkChangedField(
            compareData.club_display_name,
            club.acf.draft_content?.title,
            data.club_display_name
          ),
          full_title: checkChangedField(
            compareData.full_title,
            club.acf.draft_content?.full_title,
            data.full_title
          ),
          why_join: checkChangedField(
            compareData.why_join,
            club.acf.draft_content?.why_join,
            data.why_join
          ),
          details: {
            contact: checkChangedField(
              compareData.contact,
              club.acf.draft_content?.details.contact,
              data.contact
            ),
            email: checkChangedField(
              compareData.email,
              club.acf.draft_content?.details.email,
              data.email
            ),
            mobile: checkChangedField(
              compareData.mobile,
              club.acf.draft_content?.details.mobile,
              data.mobile
            ),
            phone: checkChangedField(
              compareData.phone,
              club.acf.draft_content?.details.phone,
              data.phone
            ),
          },
          disable_free_memberships: checkChangedField(
            compareData.disable_free_memberships,
            club.acf.draft_content?.disable_free_memberships,
            data.disable_free_memberships
          ),
          fees: checkChangedField(
            compareData.fees,
            club.acf.draft_content?.fees,
            data.fees
              .map(fee => {
                if (fee.value && fee.label) return fee;
                return null;
              })
              .filter(Boolean)
          ),
          social_links: {
            discord: checkChangedField(
              compareData.discord,
              club.acf.draft_content?.social_links.discord,
              data.discord
            ),
            facebook: checkChangedField(
              compareData.facebook,
              club.acf.draft_content?.social_links.facebook,
              data.facebook
            ),
            instagram: checkChangedField(
              compareData.instagram,
              club.acf.draft_content?.social_links.instagram,
              data.instagram
            ),
            linkedin: checkChangedField(
              compareData.linkedin,
              club.acf.draft_content?.social_links.linkedin,
              data.linkedin
            ),
            twitter: checkChangedField(
              compareData.twitter,
              club.acf.draft_content?.social_links.twitter,
              data.twitter
            ),
            tiktok: checkChangedField(
              compareData.tiktok,
              club.acf.draft_content?.social_links.tiktok,
              data.tiktok
            ),
          },
        },
      };

      if(
        acfFields.draft_content.abbr ||
        acfFields.draft_content.agm_month ||
        acfFields.draft_content.club_categories.length > 0 ||
        acfFields.draft_content.club_tags.length > 0 ||
        acfFields.draft_content.content  ||
        acfFields.draft_content.details  ||
        acfFields.draft_content.election_month  ||
        acfFields.draft_content.disable_free_memberships || 
        acfFields.draft_content.fees.length > 0  ||
        acfFields.draft_content.fomo  ||
        acfFields.draft_content.full_title  ||
        acfFields.draft_content.meetups  ||
        acfFields.draft_content.social_links.discord  ||
        acfFields.draft_content.social_links.facebook  ||
        acfFields.draft_content.social_links.instagram  ||
        acfFields.draft_content.social_links.linkedin  ||
        acfFields.draft_content.social_links.tiktok  ||
        acfFields.draft_content.social_links.twitter  ||
        acfFields.draft_content.title  ||
        acfFields.draft_content.why_join
        ) acfFields.draft_content.pending_approval = true;

      if (imageId) acfFields.draft_content.logo = imageId;
      await wpApi(`clubs/${data.id}`, 'POST', {
        fields: acfFields,
      });

      toast.success('Changes have been sent for approval.');
    } catch (error) {
      console.log(error);
      toast.error('An unexpected error has occurred.\nPlease try again.');
    } finally {
      setSaving(false);
    }
  };
  const approveChangesHandler = async rejected => {
    try {
      setSaving(true);

      let postData = {
        fields: {
          draft_content: {
            content: '',
            pending_approval: false,
            fomo: '',
            title: '',
            full_title: '',
            why_join: '',
            abbr: '',
            meetups: '',
            agm_month: '',
            election_month: '',
            disable_free_memberships: false,
            fees: [],
            club_tags: [],
            club_groups: [],
            club_categories: [],
            details: {
              contact: '',
              email: '',
              mobile: '',
              phone: '',
            },
            social_links: {
              discord: '',
              facebook: '',
              instagram: '',
              linkedin: '',
              twitter: '',
              tiktok: '',
            },
          },
        },
      };
      if (!rejected) {
        const tempData = postData;
        postData = {
          title: approvals.title
            ? club.acf.draft_content.title
            : data.club_display_name || club.title.rendered,
          content: approvals.content
            ? club.acf.draft_content.content
            : data.content || club.content.rendered,
          club_groups: data.groups.map(group => group.databaseId),
          club_tags: approvals.club_tags
            ? club.acf.draft_content.club_tags
            : data.tags.map(tag => tag.databaseId) || club.club_tags,
          club_categories: approvals.club_categories
            ? club.acf.draft_content.club_categories
            : data.categories.map(cat => cat.databaseId) || club.club_categories,
          fields: {
            ...tempData.fields,
            printer_code: data.printer_code,
            vendor_code: data.vendor_code,
            agm_month: approvals.agm_month 
              ? club.acf.draft_content.agm_month 
              : data.agm_month || club.acf.agm_month,
            election_month: approvals.election_month 
              ? club.acf.draft_content.election_month 
              : data.election_month || club.acf.election_month,
            abbr: approvals.abbr 
              ? club.acf.draft_content.abbr 
              : data.abbr || club.acf.abbr,
            full_title: approvals.full_title
              ? club.acf.draft_content.full_title
              : data.full_title || club.acf.full_title,
            fomo: approvals.fomo 
              ? club.acf.draft_content.fomo 
              : data.fomo || club.acf.fomo,
            why_join: approvals.why_join
              ? club.acf.draft_content.why_join
              : data.why_join || club.acf.why_join,
            meetups: approvals.meetups
              ? club.acf.draft_content.meetups
              : data.meetups || club.acf.meetups,
            details: {
              contact: approvals.contact
                ? club.acf.draft_content.details.contact
                : data.contact || club.acf.details.contact,
              email: approvals.email
                ? club.acf.draft_content.details.email
                : data.email || club.acf.details.email,
              mobile: approvals.mobile
                ? club.acf.draft_content.details.mobile
                : data.mobile || club.acf.details.mobile,
              phone: approvals.phone
                ? club.acf.draft_content.details.phone
                : data.phone || club.acf.details.phone,
            },
            disable_free_memberships: approvals.disable_free_memberships
              ? club.acf.draft_content.disable_free_memberships
              : data.disable_free_memberships || club.acf.disable_free_memberships,
            fees: approvals.approval_fees
              ? club.acf.draft_content.fees
              : data.fees || club.acf.fees,
            social_links: {
              discord: approvals.discord
                ? club.acf.draft_content.social_links.discord
                : data.discord || club.acf.social_links.discord,
              facebook: approvals.facebook
                ? club.acf.draft_content.social_links.facebook
                : data.facebook || club.acf.social_links.facebook,
              instagram: approvals.instagram
                ? club.acf.draft_content.social_links.instagram
                : data.instagram || club.acf.social_links.instagram,
              linkedin: approvals.linkedin
                ? club.acf.draft_content.social_links.linkedin
                : data.linkedin || club.acf.social_links.linkedin,
              twitter: approvals.twitter
                ? club.acf.draft_content.social_links.twitter
                : data.twitter || club.acf.social_links.twitter,
              tiktok: approvals.tiktok
                ? club.acf.draft_content.social_links.tiktok
                : data.tiktok || club.acf.social_links.tiktok,
            },
            club_execs: data.club_execs.map(ce => {
              const _ce = {...ce};
              _ce.start_date = _ce.start_date ? _ce.start_date.replaceAll('/', '-') : '';
              _ce.expiry_date = _ce.expiry_date ? _ce.expiry_date.replaceAll('/', '-') : '';
              return _ce;
            })
          },
        };

        if (approvals.logo) {
          postData.fields.logo = club.acf.draft_content.logo;
          postData.fields.draft_content.logo = false;
        }
      }

      await wpApi(`clubs/${data.id}`, 'POST', postData);
      toast.success('Changes have been approved.');
      navigate('/account/manage-clubs/')
    } catch (error) {
      toast.error('An unexpected error has occured.\nPlease try again.');
    } finally {
      setSaving(false);
    }
  };

  const returnBody = (body, target) => {
    changeEventHandler(target, body);
  };

  useEffect(() => {
    const pageInit = async () => {
      const initData = {
        id: club.id,
        fomo: forApproval
          ? club.acf.fomo
          : club.acf.draft_content?.fomo || club.acf.fomo,
        meetups: forApproval
          ? club.acf.meetups
          : club.acf.draft_content?.meetups || club.acf.meetups,
        why_join: forApproval
          ? club.acf.why_join
          : club.acf.draft_content?.why_join || club.acf.why_join,
        content: forApproval
          ? club.content.rendered
          : club.acf.draft_content?.content || club.content.rendered,
        club_display_name: forApproval
          ? decode(club.title.rendered)
          : decode(club.acf.draft_content?.title) || decode(club.title.rendered),
        logo: club.acf.logo,
        full_title: forApproval
          ? club.acf.full_title
          : club.acf.draft_content?.full_title || club.acf.full_title,
        agm_month: forApproval
          ? club.acf.agm_month
          : club.acf.draft_content?.agm_month || club.acf.agm_month,
        election_month: forApproval
          ? club.acf.election_month
          : club.acf.draft_content?.election_month || club.acf.election_month,
        printer_code : club.acf.printer_code,
        vendor_code : club.acf.vendor_code,
        abbr: forApproval
          ? club.acf.abbr
          : club.acf.draft_content?.abbr || club.acf.abbr,
        disable_free_memberships: forApproval
          ? club.acf.disable_free_memberships
          : club.acf.draft_content?.disable_free_memberships || club.acf.disable_free_memberships,
        contact: forApproval
          ? club.acf.details.contact
          : club.acf.draft_content?.details.contact || club.acf.details.contact,
        email: forApproval
          ? club.acf.details.email
          : club.acf.draft_content?.details.email || club.acf.details.email,
        mobile: forApproval
          ? club.acf.details.mobile
          : club.acf.draft_content?.details.mobile || club.acf.details.mobile,
        phone: forApproval
          ? club.acf.details.phone
          : club.acf.draft_content?.details.phone || club.acf.details.phone,
        twitter: forApproval
          ? club.acf.social_links.twitter
          : club.acf.draft_content?.social_links.twitter ||
            club.acf.social_links.twitter,
        facebook: forApproval
          ? club.acf.social_links.facebook
          : club.acf.draft_content?.social_links.facebook ||
            club.acf.social_links.facebook,
        instagram: forApproval
          ? club.acf.social_links.instagram
          : club.acf.draft_content?.social_links.instagram ||
            club.acf.social_links.instagram,
        linkedin: forApproval
          ? club.acf.social_links.linkedin
          : club.acf.draft_content?.social_links.linkedin ||
            club.acf.social_links.linkedin,
        discord: forApproval
          ? club.acf.social_links.discord
          : club.acf.draft_content?.social_links.discord ||
            club.acf.social_links.discord,
        tiktok: forApproval
          ? club.acf.social_links.tiktok
          : club.acf.draft_content?.social_links.tiktok ||
            club.acf.social_links.tiktok,
        club_execs: club.acf.club_execs
      };

      let parseCategories = club.club_categories;
      if (get(club, "acf.draft_content.club_categories", []).length > 0 && !forApproval) {
        parseCategories = club.acf.draft_content?.club_categories;
      }
      initData.categories = findObjectByDatabaseId(
        parseCategories,
        allWpClubCategory?.nodes
      );

      let parseTags = club.club_tags;
      if (get(club, "acf.draft_content.club_tags", false).length > 0 && !forApproval) {
        parseTags = club.acf.draft_content?.club_tags;
      }
      initData.tags = findObjectByDatabaseId(parseTags, allWpClubTag?.nodes);

      if (forApproval) {
        let parseGroups = club.club_groups;
        initData.groups = findObjectByDatabaseId(parseGroups, allWpClubGrouping?.nodes);
      }

      let parseFees =
        club.acf.fees.length > 0 ? club.acf.fees : [{ value: '', label: '' }];
      if (!forApproval && get(club, "acf.draft_content.fees", []).length > 0)
        parseFees = club.acf.draft_content?.fees;
      initData.fees = parseFees;

      if (initData.club_execs && initData.club_execs.length > 0) {
        const memberNumbers = initData.club_execs.map(ce => ce.member_number);
        const people = await getPerson(['first', 'last', 'email', 'MemberNumber'], memberNumbers, 'str:cm:membernumber');
        
        initData.club_execs = initData.club_execs.map((ce, ice) => {
          const updatedCe = {...ce, index: ice};
          if (String(people.status).startsWith('2') && people.response.contacts) {
            const person = people.response.contacts.find(p => p.fields['str:cm:membernumber'] === ce.member_number);
            if (person) {
              updatedCe.name = `${person.fields['str::first']} ${person.fields['str::last']}`;
              if (!updatedCe.email || updatedCe.email === '') updatedCe.email = person.fields['str::email'];
            } else {
              updatedCe.name = 'Not yet defined';
            }
          } else {
            updatedCe.name = 'Not yet defined';
          }
          return updatedCe;
        });
      }

      setData({ ...data, ...initData });
      setCompareData({ ...data, ...initData });

      if (forApproval) {
        if (get(club, "acf.draft_content.logo", false)) {
          const draftImage = await wpApi(
            `media/${club.acf.draft_content?.logo}`,
            'GET'
          );
          if (draftImage.status === 200)
            setLogoDraftImage(draftImage.response.guid.rendered);
        }

        if (get(club, "acf.draft_content.club_categories", []).length > 0)
          setPendingCategories(
            findObjectByDatabaseId(
              club.acf.draft_content?.club_categories,
              allWpClubCategory?.nodes
            )
          );
        if (get(club, "acf.draft_content.club_tags", []).length > 0)
          setPendingTags(
            findObjectByDatabaseId(
              club.acf.draft_content?.club_tags,
              allWpClubTag?.nodes
            )
          );
      }
    };
    pageInit();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  let searchedTags = allWpClubTag?.nodes;
  let searchedGroups = allWpClubGrouping?.nodes;

  if (tagSearch)
    searchedTags = allWpClubTag?.nodes.filter(node =>
      node.name.includes(tagSearch.toLowerCase())
    );

  if (groupSearch)
    searchedGroups = allWpClubGrouping?.nodes.filter(node =>
      node.name.includes(groupSearch.toLowerCase())
  );

  return (
    <>
      <AccordionCard title={'Club details'}>
        <div className={styles.root}>
          <div className={styles.logo}>
            <div>
              <input
                accept='.jpg,.jpeg,.gif,.png'
                type={'file'}
                className={styles.upload}
                ref={fileRef}
                multiple={false}
                onChange={e => {
                  if (
                    e.currentTarget?.files &&
                    e.currentTarget.files.length > 0
                  )
                    setLogoImage(e.currentTarget.files?.[0]);
                }}
              />
              <p>Logo</p>
              <p>Must be JPEG, PNG, or GIF and cannot exceed 1MB.</p>

              {(logoImage || data?.logo) && (
                <img
                  src={logoImage ? URL.createObjectURL(logoImage) : data?.logo}
                  alt={data?.club_display_name}
                />
              )}
            </div>
            {forApproval && logoDraftImage ? (
              <>
                <div
                  className={`${styles.draftImage} ${styles.approvalContainer}`}>
                  <Toggle
                    id={'title-logo'}
                    checked={approvals.logo}
                    onChange={() => approvalHandler('logo', !approvals.logo)}
                    label={'Approve?'}
                  />
                  <img src={logoDraftImage} alt={data?.club_display_name} />
                </div>
              </>
            ) : (
              <Button
                className={['smallButtonGray', styles.select].join(' ')}
                type='span'
                onClick={() => fileRef.current.click()}>
                Select File
              </Button>
            )}
          </div>

          {(get(club, "acf.draft_content.title", false) && forApproval) && (
            <ApprovalToggle
              title={'PENDING DISPLAY NAME'}
              checked={approvals.title}
              id={'title'}
              onChange={approvalHandler}
              content={club.acf.draft_content.title}
            />
          )}
          <FormInputField
            id='club_display_name'
            type='input'
            labelName='Club Display Name'
            value={data?.club_display_name}
            handleChange={changeEventHandler}
            disabled={(get(club, "acf.draft_content.title", false) && forApproval)}
          />

          {(get(club, "acf.draft_content.full_title", false) && forApproval) && (
            <ApprovalToggle
              title={'PENDING FULL TITLE'}
              checked={approvals.full_title}
              id={'full_title'}
              onChange={approvalHandler}
              content={club.acf.draft_content.full_title}
            />
          )}

          <FormInputField
            id='full_title'
            type='input'
            labelName='Full Club Name'
            value={data?.full_title}
            handleChange={changeEventHandler}
            disabled={(get(club, "acf.draft_content.full_title", false) && forApproval)}
          />

          {(get(club, "acf.draft_content.abbr", false) && forApproval) && (
            <ApprovalToggle
              title={'PENDING ABBREVIATION'}
              checked={approvals.abbr}
              id={'abbr'}
              onChange={approvalHandler}
              content={club.acf.draft_content.abbr}
            />
          )}
          <FormInputField
            id='abbr'
            type='input'
            labelName='Club abbreviation'
            value={data?.abbr}
            handleChange={changeEventHandler}
            disabled={(get(club, "acf.draft_content.abbr", false) && forApproval)}
          />
          <div className={`grid grid-50 grid-responsive `}>
            <div>
              {(get(club, "acf.draft_content.details.contact", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING CONTACT NAME'}
                  checked={approvals.contact}
                  id={'contact'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.details.contact}
                />
              )}

              <FormInputField
                id='contact'
                type='input'
                labelName='Contact Name'
                value={data?.contact}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.details.contact", false) && forApproval)}
              />
            </div>

            <div>
              {(get(club, "acf.draft_content.details.phone", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING CONTACT PHONE'}
                  checked={approvals.phone}
                  id={'phone'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.details.phone}
                />
              )}
              <FormInputField
                id='phone'
                type='input'
                labelName='Contact Phone'
                value={data?.phone}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.details.phone", false) && forApproval)}
              />
            </div>
          </div>
          <div className={`grid grid-50 grid-responsive `}>
            <div>
              {(get(club, "acf.draft_content.details.mobile", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING CONTACT MOBILE'}
                  checked={approvals.mobile}
                  id={'mobile'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.details.mobile}
                />
              )}
              <FormInputField
                id='mobile'
                type='text'
                labelName='Contact Mobile'
                value={data?.mobile}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.details.mobile", false) && forApproval)}
              />
            </div>

            <div>
              {(get(club, "acf.draft_content.details.email", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING CONTACT EMAIL'}
                  checked={approvals.email}
                  id={'email'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.details.email}
                />
              )}
              <FormInputField
                id='email'
                type='email'
                labelName='Contact Email'
                value={data?.email}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.details.email", false) && forApproval)}
              />
            </div>
          </div>

          {(get(club, "acf.draft_content.content", false) && forApproval) && (
            <ApprovalToggle
              title={'PENDING DESCRIPTION'}
              checked={approvals.content}
              id={'content'}
              onChange={approvalHandler}
              content={club.acf.draft_content.content}
            />
          )}
          <p className={styles.label}>Description</p>
          {compareData && ( // Ensure data is loaded before rendering textfield
            <RichTextField
              defaultValue={data.content}
              returnValue={returnBody}
              target='content'
              readOnly={(get(club, "acf.draft_content.content", false) && forApproval)}
            />
          )}

          {(get(club, "acf.draft_content.why_join", false) && forApproval) && (
            <ApprovalToggle
              title={'PENDING WHY JOIN'}
              checked={approvals.why_join}
              id={'why_join'}
              onChange={approvalHandler}
              content={club.acf.draft_content.why_join}
            />
          )}
          <p className={styles.label}>Why Join?</p>
          {compareData && ( // Ensure data is loaded before rendering textfield
            <RichTextField
              defaultValue={data.why_join}
              returnValue={returnBody}
              target='why_join'
              readOnly={(get(club, "acf.draft_content.why_join", false) && forApproval)}
            />
          )}

          {(get(club, "acf.draft_content.fomo", false) && forApproval) && (
            <ApprovalToggle
              title={'PENDING FOMO'}
              checked={approvals.fomo}
              id={'fomo'}
              onChange={approvalHandler}
              content={club.acf.draft_content.fomo}
            />
          )}
          <p className={styles.label}>Fomo</p>
          {compareData && ( // Ensure data is loaded before rendering textfield
            <RichTextField
              defaultValue={data.fomo}
              returnValue={returnBody}
              target='fomo'
              readOnly={(get(club, "acf.draft_content.fomo", false) && forApproval)}
            />
          )}

          {(get(club, "acf.draft_content.meetups", false) && forApproval) && (
            <ApprovalToggle
              title={'PENDING WHY MEETUPS'}
              checked={approvals.meetups}
              id={'meetups'}
              onChange={approvalHandler}
              content={club.acf.draft_content.meetups}
            />
          )}

          <p className={styles.label}>Meetups</p>
          {compareData && ( // Ensure data is loaded before rendering textfield
            <RichTextField
              defaultValue={data?.meetups || ''}
              returnValue={returnBody}
              target='meetups'
              readOnly={(get(club, "acf.draft_content.meetups", false) && forApproval)}
            />
          )}

          <div className={`grid grid-50 grid-responsive ${styles.mt}`}>
            <div>
              {(get(club, "acf.draft_content.agm_month", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING AGM MONTH'}
                  checked={approvals.agm_month}
                  id={'agm_month'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.agm_month}
                />
              )}
              <SelectField
                id='agm_month'
                label={'AGM Month'}
                placeholder={'Select'}
                value={data?.agm_month}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.agm_month", false) && forApproval)}
                data={months.map(month => {
                  return {
                    value: month,
                    text: month,
                  };
                })}
              />
            </div>
            <div>
              {(get(club, "acf.draft_content.election_month", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING FINANCIAL MONTH'}
                  checked={approvals.election_month}
                  id={'election_month'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.election_month}
                />
              )}
              <SelectField
                id='election_month'
                label={'Financial Month'}
                placeholder={'Select'}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.election_month", false) && forApproval)}
                value={data?.election_month}
                data={months.map(month => {
                  return {
                    value: month,
                    text: month,
                  };
                })}
              />
            </div>
          </div>
          {forApproval && 
          <div className={`grid grid-50 grid-responsive ${styles.mt}`}>
            <div>
              <FormInputField
                id='printer_code'
                type='input'
                labelName='Printer Code'
                value={data?.printer_code}
                handleChange={changeEventHandler}
              />
            </div>
            <div>
            <FormInputField
                id='vendor_code'
                type='input'
                labelName='Vendor Code'
                value={data?.vendor_code}
                handleChange={changeEventHandler}
              />
            </div>
          </div>
          }
          
        </div>
      </AccordionCard>
      <AccordionCard title={'Membership Fee'}>
        <div className={styles.root}>
          <div className='mb-4'>
            {(get(club, "acf.draft_content.disable_free_memberships", false) && forApproval) && (
              <ApprovalToggle
                title={'PENDING DISABLE FREE MEMBERSHIPS'}
                checked={approvals.disable_free_memberships}
                id={'disable_free_memberships'}
                onChange={approvalHandler}
                content={club.acf.draft_content.disable_free_memberships}
              />
            )}
            <Checkbox
                key={'disable_free_memberships'}
                value={true}
                action={disableFeesChangeHandler}
                disabled={(get(club, "acf.draft_content.disable_free_memberships", false) && forApproval)}
                isChecked={data?.disable_free_memberships}
                label={'Disable free memberships?'}
            />
          </div>
          {(get(club, "acf.draft_content.fees", []).length > 0 && forApproval) && (
            <ApprovalToggle
              title={'PENDING MEMBERSHIP FEE'}
              checked={approvals.approval_fees}
              id={'approval_fees'}
              onChange={approvalHandler}
              content={club.acf.draft_content.fees.map(
                fee => `Value: ${fee.value} Label:${fee.label}<br/>`
              )}
            />
          )}
          
          {data?.fees.map((fee, index) => (
            <div key={index} className={`flex-between`} style={{gap: '30px'}}>
              <div className={styles.half}>
                <FormInputField
                  id='value'
                  value={fee.value}
                  type='text'
                  labelName='Value'
                  disabled={(get(club, "acf.draft_content.fees", []).length > 0 && forApproval)}
                  customHandleChange={e =>
                    feeChangeHandler(e.currentTarget.value, 'value', index)
                  }
                />
              </div>
              <div className={styles.half}>
                <FormInputField
                  id='label'
                  value={fee.label}
                  type='text'
                  labelName='Label'
                  disabled={(get(club, "acf.draft_content.fees", []).length > 0 && forApproval)}
                  customHandleChange={e =>
                    feeChangeHandler(e.currentTarget.value, 'label', index)
                  }
                />
              </div>
              <span role="presentation" className={styles.remove} onClick={() => removeFee(index)}><Icon symbol="close" /></span>
            </div>
          ))}

          <hr />
          <div>
            <span
              role={'button'}
              className={styles.membership}
              onClick={() => feeChangeHandler(null, null, null, true)}
              onKeyDown={() => feeChangeHandler(null, null, null, true)}
              tabIndex={0}>
              <Add />
              Add
            </span>
          </div>
        </div>
      </AccordionCard>

      <AccordionCard title={'Categories'}>
        {(forApproval && pendingCategories.length > 0) && (
          <ApprovalToggle
            title={'PENDING CATEGORIES'}
            checked={approvals.club_categories}
            id={'club_categories'}
            onChange={approvalHandler}
            content={pendingCategories.map(cat => cat.name).join(' , ')}
          />
        )}
        <div className={[styles.root, styles.categories].join(' ')}>
          {allWpClubCategory?.nodes?.map(node => (
            <Checkbox
              key={node.databaseId}
              value={node.databaseId}
              action={categoryChangeHandler}
              disabled={(forApproval && pendingCategories.length > 0)}
              isChecked={
                !!data?.categories.find(
                  cat => cat.databaseId === node.databaseId
                )
              }
              label={node.name}
            />
          ))}
        </div>
      </AccordionCard>

      <AccordionCard title={'Tags'}>
        <div className={[styles.root, styles.autocomplete].join(' ')}>
          {!forApproval && (
            <>
              <label htmlFor='autocomplete' className={styles.label}>
                Share and add tags
              </label>
              <div id='autocomplete' className={styles.searchContainer}>
                <input
                  value={tagSearch}
                  onChange={e => setTagSearch(e.currentTarget.value)}
                  onFocus={() => setShowTagItems(true)}
                  onBlur={() => setShowTagItems(false)}
                />
                <Search />
              </div>

              {showTagItems ? (
                <ul>
                  {searchedTags.length > 0 ? (
                    <>
                      {searchedTags.map(node => (
                        <li
                          key={node.databaseId}
                          role='presentation'
                          onMouseDown={() => tagChangeHandler(node)}>
                          {node.name}
                        </li>
                      ))}
                    </>
                  ) : (
                    <p>No tags found</p>
                  )}
                </ul>
              ) : null}

            <div className={styles.addTo}>
              Can't find a tag to suit your requirement? <Button type="span" link={true} onClick={() => setToggleNewTag(true)}>Create one</Button>
            </div>

            <Dialog open={toggleNewTag} size="xs" okBtnText='Create' onOk={() => createNewTag()} onCancel={() => setToggleNewTag(false)}>
              <FormInputField labelName="Tag name" handleChange={handleNewTag} />
            </Dialog>
            </>
          )}

          {(forApproval && pendingTags.length > 0) && (
            <ApprovalToggle
              title={'PENDING TAGS'}
              checked={approvals.club_tags}
              id={'club_tags'}
              onChange={approvalHandler}
              content={pendingTags.map(tag => tag.name).join(' , ')}
            />
          )}
          <div className={styles.tags}>
            {data?.tags.map(tag => (
              <div className={styles.tag} key={tag.databaseId}>
                {tag.name}
                {!forApproval && (
                  <Delete clickHandler={() => tagChangeHandler(tag, true)} />
                )}
              </div>
            ))}
          </div>
        </div>
      </AccordionCard>

      {forApproval && (
        <AccordionCard title={'Groups'}>
          <div className={[styles.root, styles.autocomplete].join(' ')}>
            <label htmlFor='autocomplete' className={styles.label}>
              Internally group club for management functionality (ie defining resources to a specific group)
            </label>
            <div id='autocomplete' className={styles.searchContainer}>
              <input
                value={groupSearch}
                onChange={e => setGroupSearch(e.currentTarget.value)}
                onFocus={() => setShowGroupItems(true)}
                onBlur={() => setShowGroupItems(false)}
              />
              <Search />
            </div>

            {showGroupItems ? (
              <ul>
                {searchedGroups.length > 0 ? (
                  <>
                    {searchedGroups.map(node => (
                      <li
                        key={node.databaseId}
                        role='presentation'
                        onMouseDown={() => groupChangeHandler(node)}>
                        {node.name}
                      </li>
                    ))}
                  </>
                ) : (
                  <p>No groups found</p>
                )}
              </ul>
            ) : null}

            <div className={styles.addTo}>
              Can't find a group to suit your requirement? <Button type="span" link={true} onClick={() => setToggleNewGroup(true)}>Create one</Button>
            </div>

            <Dialog open={toggleNewGroup} size="xs" okBtnText='Create' onOk={() => createNewGroup()} onCancel={() => setToggleNewGroup(false)}>
              <FormInputField labelName="Group name" handleChange={handleNewGroup} />
            </Dialog>

            <div className={styles.tags}>
              {data?.groups.map(group => (
                <div className={styles.tag} key={group.databaseId}>
                  {group.name}
                  <Delete clickHandler={() => groupChangeHandler(group, true)} />
                </div>
              ))}
            </div>
          </div>
        </AccordionCard>
      )}

      <AccordionCard title={'Social links'}>
        <div className={styles.root}>
          <div className={`grid grid-50 grid-responsive `}>
            <div>
              {(get(club, "acf.draft_content.social_links.twitter", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING TWITTER'}
                  checked={approvals.twitter}
                  id={'twitter'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.social_links.twitter}
                />
              )}
              <FormInputField
                id='twitter'
                value={data?.twitter}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.social_links.twitter", false) && forApproval)}
                type='input'
                labelName='Twitter'
              />
            </div>

            <div>
              {(get(club, "acf.draft_content.social_links.instagram", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING INSTAGRAM'}
                  checked={approvals.instagram}
                  id={'instagram'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.social_links.instagram}
                />
              )}
              <FormInputField
                id='instagram'
                value={data?.instagram}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.social_links.instagram", false) && forApproval)}
                type='input'
                labelName='Instagram'
              />
            </div>
          </div>
          <div className={`grid grid-50 grid-responsive `}>
            <div>
              {(get(club, "acf.draft_content.social_links.facebook", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING FACEBOOK'}
                  checked={approvals.facebook}
                  id={'facebook'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.social_links.facebook}
                />
              )}
              <FormInputField
                id='facebook'
                value={data?.facebook}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.social_links.facebook", false) && forApproval)}
                type='input'
                labelName='Facebook'
              />
            </div>

            <div>
              {(get(club, "acf.draft_content.social_links.tiktok", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING TIKTOK'}
                  checked={approvals.tiktok}
                  id={'tiktok'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.social_links.tiktok}
                />
              )}
              <FormInputField
                id='tiktok'
                value={data?.tiktok}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.social_links.tiktok", false) && forApproval)}
                type='input'
                labelName='TikTok'
              />
            </div>
          </div>
          <div className={`grid grid-50 grid-responsive `}>
            <div>
              {(get(club, "acf.draft_content.social_links.linkedin", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING LINKEDIN'}
                  checked={approvals.linkedin}
                  id={'linkedin'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.social_links.linkedin}
                />
              )}
              <FormInputField
                id='linkedin'
                value={data?.linkedin}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.social_links.linkedin", false) && forApproval)}
                type='input'
                labelName='Linked In'
              />
            </div>

            <div>
              {(get(club, "acf.draft_content.social_links.discord", false) && forApproval) && (
                <ApprovalToggle
                  title={'PENDING DISCORD'}
                  checked={approvals.discord}
                  id={'discord'}
                  onChange={approvalHandler}
                  content={club.acf.draft_content.social_links.discord}
                />
              )}
              <FormInputField
                id='discord'
                value={data?.discord}
                handleChange={changeEventHandler}
                disabled={(get(club, "acf.draft_content.social_links.discord", false) && forApproval)}
                type='input'
                labelName='Discord'
              />
            </div>
          </div>
        </div>
      </AccordionCard>

      {forApproval && (
        <>
          <AccordionCard title="Club Executives">
            <DataTable
              tableData={data.club_execs}
              headingKeys={[
                { label: 'Member', data_key: 'name', sortable: true },
                { label: 'Position', data_key: 'position', sortable: true },
                { label: 'Start Date', data_key: 'start_date' },
              ]}
              rowActions={[
                { label: 'Edit', cta: data => editExec(data) },
                { label: 'Remove', cta: data => removeExec(data) },
              ]}
              topActions={[
                {
                  label: 'Add Exec',
                  icon: <Icon symbol='fileOther' />,
                  event: () => editExec({index: data.club_execs.length || 0}),
                },
              ]}
              allowExport={true}
            />
            <Dialog open={execData} size="sm" okBtnText='Update' onOk={() => submitExec()} onCancel={() => editExec(false)}>
              {execData.name && (
                <FormInputField
                  id='name'
                  disabled={true}
                  value={execData.name || ''}
                  type='text'
                  labelName='Name'
                  customHandleChange={e =>
                    clubExecChangeHandler(e.currentTarget.value, 'name')
                  }
                />
              )}
              <FormInputField
                id='member_number'
                value={execData.member_number || ''}
                type='text'
                labelName='Member Number'
                customHandleChange={e =>
                  clubExecChangeHandler(e.currentTarget.value, 'member_number')
                }
                handleBlur={(id, value) => fetchExecDetails(value)}
              />
              <FormInputField
                id='position'
                value={execData.position || ''}
                type='text'
                labelName='Position'
                customHandleChange={e =>
                  clubExecChangeHandler(e.currentTarget.value, 'position')
                }
              />
              <FormInputField
                id='email'
                value={execData.email || ''}
                type='text'
                labelName='Email'
                customHandleChange={e =>
                  clubExecChangeHandler(e.currentTarget.value, 'email')
                }
              />
              <FormInputField
                id='start_date'
                value={execData.start_date || ''}
                type='text'
                labelName='Start Date'
                customHandleChange={e =>
                  clubExecChangeHandler(e.currentTarget.value, 'start_date')
                }
              />
              <FormInputField
                id='expiry_date'
                value={execData.expiry_date || ''}
                type='text'
                labelName='Expiry Date'
                customHandleChange={e =>
                  clubExecChangeHandler(e.currentTarget.value, 'expiry_date')
                }
              />
            </Dialog>
          </AccordionCard>

          <AccordionCard title="Meeting History" showContent={false} fetchOutput={fetchHistory}>
              Fetching history...
          </AccordionCard>
        </>
      )}
      
      {forApproval ? (
        <div className={`grid grid-50 grid-responsive `}>
          <Button
            disabled={saving}
            type={'button'}
            onClick={() => approveChangesHandler(true)}
            level={'secondary'}>
            Reject
          </Button>
          <Button
            disabled={saving}
            type={'button'}
            onClick={() => approveChangesHandler(false)}
            level={'primary'}>
            Approve
          </Button>
        </div>
      ) : (
        <Button
          disabled={saving}
          type={'button'}
          onClick={submitClubHandler}
          level={'primary'}>
          Submit changes
        </Button>
      )}
    </>
  );
};

export default ClubDetails;

